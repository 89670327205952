<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle text="Payment Details" icon="credit-card" />
    <div class="mt-2 mx-auto" style="margin-top:20px !important;width:100%;color: var(--pr-color);font-size: 0.9rem;">
      <div v-if="!failed">
        <div v-if="success">
          Your payment method has been succesfully updated! Your future monthly payments will now be charged to the card ending "<span class="text-nowrap text-white">{{ pan }}</span>"
        </div>
        <div v-else>
          <div v-if="pan" class="primary-colour mb-2">
            Payments are currently being charged to the card ending "<span class="text-nowrap text-white">{{ pan }}</span>"
            <span>
              To update your existing card details, please amend the form shown below
              (<span class="text-white">note you may be charged 1p for this validation depending on which bank you use</span>).
            </span>
          </div>
          <div v-else class="primary-colour mb-2">
            You can amend your payment details after you have purchased your first hero.
          </div>
        </div>
      </div>
      <div
        v-else
        style="border: solid 2px red;padding: 10px;margin-top: 15px;margin-bottom: 30px;"
      >
        <span style="color:red;">
          Unfortunately your payment method was not successfully updated
          <br><br>
          Please check your card details and try again.
          <br><br>
          Note: the reason we have been given regarding this is as follows: <strong>{{ errMsg }}</strong></span>      
      </div>
      <div v-if="!success" style="height:fit-content;margin-top:30px;font-size:0.9rem;">
        <div v-show="pan" id="stPayment">
          <form
            id="st-form"
            :action="returnVal"
            method="POST"
          >
            <div style="padding:0px;">
              <div id="st-card-number" style="margin-top:10px;margin-bottom: -15px!important;" />
              <div id="st-expiration-date" style="margin-bottom: -15px!important;" />
              <div id="st-security-code" style="margin-bottom: -20px!important;" />
            </div>
            <div style="margin-top:10px;" :style="{'opacity': mobileView ? 'right' : 'left'}">
              <mdb-btn
                id="st-form__submit"
                type="submit"
                class="btn primary-btn btn-radius m-0"
                style="width:110px;margin:5px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
                size="sm"
              >
                UPDATE
              </mdb-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import basketService from '@/api-services/basket.service'
import userService from '@/api-services/user.service'
import { API_URL } from '@/common/config'
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'

export default {
  name: 'MyCard',
  components: {
    LeftTopPanelTitle
  },
  data () {
    return {
      buttonClick: false,
      addedToBasket: false,
      errMsg: '',
      returnVal: API_URL + '/api/game/auth',
      success: false,
      failed: false,
      myCardModal: false,
      pan: ''
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'mobileView'
    ])
  },
  created () {
    this.getPan()
    this.payment()
  },
  mounted () {
    window.card = this
  },
  methods: {
    payment () {
      basketService.getAuthToken(this.userData.access_token).then((results) => {
        this.checkOutProcessStep = 2
        var scriptTag = '(function() { var st = SecureTrading({ jwt: "' + results.data + '", '
        scriptTag += 'animatedCard: true, livestatus: 1, panIcon: true, submitOnSuccess: false, submitCallback: process, translations : { "Pay": "UPDATE", "Card number": "CARD NUMBER", "Card number": "CARD NUMBER" , "Expiration date": "EXPIRATION DATE" , "Security code": "SECURITY CODE"  }, '
        scriptTag += 'styles: { "border-radius-input" : "0px", "border-color-input" : "#ffffff", "border-size-input" : "1px", "space-outset-body" : "5px", "space-outset-input" : "1px", "font-size-label": "14px", "color-input" : "#ffffff", "background-color-input" : "#000000", "background-color-body" : "#000000", "color-label" : "#1FFC35" }}); '
        scriptTag += 'st.Components(); '
        scriptTag += '  function process(data) { '
        scriptTag += '    var xhttp = new XMLHttpRequest(); '
        scriptTag += '    xhttp.onreadystatechange = function() { '
        scriptTag += '      if (this.readyState == 4) { '
        scriptTag += '        if (this.status == 200) { '
        scriptTag += '          window.card.updateDone(1, ""); '
        scriptTag += '        } else { '
        scriptTag += '          var err = eval("(" + this.responseText + ")");'
        scriptTag += '          window.card.updateDone(2, err.Message); '
        scriptTag += '        }; '
        scriptTag += '      }; '
        scriptTag += '    }; '
        scriptTag += '    xhttp.open("POST", "' + API_URL + '/api/game/auth", true); '
        scriptTag += '    xhttp.setRequestHeader("Content-type", "application/json"); '
        scriptTag += '    xhttp.setRequestHeader("Authorization", "Bearer ' + this.userData.access_token + '"); '
        scriptTag += '    xhttp.send(data.jwt); '
        scriptTag += '  }; '
        scriptTag += '})(); '
        var g = document.createElement('script')
        g.type = 'text/javascript'
        g.async = true
        var s = document.getElementsByTagName('script')[0]
        g.text = scriptTag
        s.parentElement.insertBefore(g, s)
      })
    },
    updateDone (type, msg) {
      if (type === 1) {
        userService.getPan(this.userData.access_token).then((results) => {
          results.data.access_token = this.userData.access_token
          this.$store.commit('storeUserData', results.data)
          this.pan = results.data.pan
      }).catch(() => {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      })
        this.success = true
      } else {
        this.failed = true
      }
      this.errMsg = msg
    },
    getPan () {
      userService.getPan(this.userData.access_token).then((results) => {
        this.pan = results.data.pan
      }).catch(() => {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      })
    }
  }
}
</script>
