<template>
  <MainContainer :no-left-buttons="true">
    <template #leftPanelTop>
      <PaymentDetails />
    </template>

    <template #rightPanel>
      <SideMenu />
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import SideMenu from '@/components/navbar/sideMenu.vue'
import MainContainer from '@/components/layout/mainContainer.vue'
import PaymentDetails from '@/components/paymentDetails/paymentDetails.vue'

export default {
  name: 'PaymentDetailsContainer',
  components: {
    MainContainer, SideMenu, PaymentDetails
  },
  computed: {
    ...mapGetters(['userData'])
  }
}
</script>
